/**
 * Copyright ©2022 Drivepoint
 */

import {useEffect, useMemo, useState} from "react";
import ServiceRegistry from "../services/ServiceRegistry";
import useStateChange from "./useStateChange";
import BainbridgeUser from "../services/user/BainbridgeUser";

export type PathContextProps = {
  companyId: string | undefined;
};

export default function usePathContext(): any {

  const params = ServiceRegistry.routeService.getCurrentPageParams();
  const user = useStateChange<BainbridgeUser>("user");

  const [companyId, setCompanyId] = useState<string | undefined>();

  useEffect(() => {
    if (params[":company"]) {
      ServiceRegistry.companyService.setCompanyById(params[":company"]);
      setCompanyId(params[":company"]);
    }
  }, [location, params]);

  useEffect(() => {
    if (!user) {
      setCompanyId(undefined);
    }
  }, [user]);

  return useMemo<PathContextProps>(() => {
    return {
      companyId: companyId
    };
  }, [companyId]);

}
