/**
 * Copyright ©2023 Drivepoint
 */

import {User} from "firebase/auth";
import WebAppServerClient from "../clients/WebAppServerClient";

export default class BainbridgeUser {

  public excelUser: any;
  public companies: any[] = [];

  constructor(public user: User) {
  }

  async load(): Promise<void> {
    const data = await WebAppServerClient.request("/ui/bainbridge_user", "GET");
    this.excelUser = data.excelUser;
    this.companies = data.companies;
  }

}
