/**
 * Copyright ©2022 Drivepoint
 */

import {LicenseInfo} from "@mui/x-license-pro";

export default class Licenses {

  static init(): void {
    LicenseInfo.setLicenseKey(
      "e7148b3b7e2c72a8dc31291da5257ed3Tz03MTUwNSxFPTE3MjIwMTYzOTgwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y"
    );
  }

}
